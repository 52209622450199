import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export default class section8 {
	constructor() {
		this.section = document.querySelector(".section8-js");
		this.trigger = this.section.querySelector(".sticky-trigger-js");
		this.sticky = document.querySelector(".section8-sticky-wrapper-js");
		this.stickyEnd = document.querySelector(".section8-scrolling-js");

		this.init();
	}

	init() {
		let mm = gsap.matchMedia();

		mm.add("(min-width: 1200px)", () => {
			ScrollTrigger.create({
				//markers: true,
				trigger: this.trigger,
				pin: this.sticky,
				start: "top top",
				end: "bottom bottom",
			});
		});
	}
}
