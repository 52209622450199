import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

export default class transition {
	constructor() {
		this.char = document.querySelectorAll(".tr-char-js");
		this.logoParts = document.querySelectorAll(".tr-logo-js");
		this.logoSVG = document.querySelector(".transition-logo-js");
		this.container = document.querySelector(".transition-section");
	}

	loadingAniPlay() {
		gsap.set(this.logoParts, { scale: 0, transformOrigin: "center", x: 361 / 2 - 48 });
		gsap.to(this.logoParts, { scale: 1, ease: "power4", stagger: 0.1, duration: 1 });
		gsap.to(this.logoParts, { x: 0, ease: "power4", duration: 1, delay: 0.5 });
		gsap.from(this.char, { duration: 0.3, opacity: 0, x: -20, ease: "power4", stagger: 0.1, delay: 0.55 });
		gsap.to(this.logoSVG, {
			opacity: 0,
			duration: 0.3,
			delay: 2,
			onComplete: () => {
				window.scrollTo(0, 0);
				window.lenis.stop();
				gsap.set("nav.sticky", { y: "-100%" });
			},
		});
		gsap.to(this.container, {
			height: 0,
			duration: 0.8,
			ease: "power4",
			delay: 2,
			onComplete: () => {
				window.lenis.start();
			}
		});
	}

	play() {
		//console.log("playing");
		gsap.set(this.logoSVG, { opacity: 1 });
		gsap.set(this.logoParts, { scale: 0, transformOrigin: "center", x: 361 / 2 - 48 });
		gsap.fromTo(
			this.container,
			{
				height: 0,
				duration: 1,
				ease: "power4",
			},
			{
				height: "100vh",
				onComplete: () => {
					window.scrollTo(0, 0);
					window.lenis.stop();
					gsap.set("nav.sticky", { y: "-100%" });
				},
			}
		);
		gsap.to(this.logoParts, { scale: 1, ease: "power4", stagger: 0.1, duration: 1, delay: 0.8 });
		gsap.to(this.logoParts, { x: 0, ease: "power4", duration: 1, delay: 1.3 });
		gsap.from(this.char, { duration: 0.3, opacity: 0, x: -20, ease: "power4", stagger: 0.1, delay: 1.45 });
		gsap.to(this.logoSVG, { opacity: 0, duration: 0.3, delay: 2.2 });
		gsap.to(this.container, {
			height: 0,
			duration: 0.8,
			ease: "power4",
			delay: 2.2,
			onComplete: () => {
				window.lenis.start();
			}
		});
		
	}
}
