/*-------------------------------------------------------------
IMPORTED
-------------------------------------------------------------*/
import barba from "@barba/core/dist/barba.js";
import gsap from "gsap";

//Core
import Scroll from "./utils/smoothscroll_Class.js";
import transition from "./components/transition_Class.js";
import nav from "./components/nav_Class.js";
import appearAnimations from "./utils/appearAnimation.js";
import stickyTitles from "./utils/stickyTitle.js";
import masker from "./utils/masker.js";
import { getDetector, setDetector } from "./utils/browserDtector.js";

//Form
import modalClass from "./components/modal.js";
import { formController } from "./utils/formController.js";

//Organization
import organizationClass from "./pages/organization/organization.js";


//Pages
import mainpage from "./pages/mainpage/mainpage.js";
import gallery from "./pages/gallery/gallery.js";
import product from "./pages/product/product.js";

/*-------------------------------------------------------------
Vars
-------------------------------------------------------------*/
setDetector();
var organization, pageTransition, scroll, stickyTitle, pictureMask, navigation, modal;
gsap.set(document.querySelector("main"), { opacity: 0 });

window.addEventListener("load", function () {
	
	gsap.to(document.querySelector("main"), { opacity: 1 });
	scroll = new Scroll();
	pageTransition = new transition();
	pageTransition.loadingAniPlay();
	window.scrollTo(0, 0);
	
	pictureMask = new masker();
	appearAnimations();
	stickyTitle = new stickyTitles();

	navigation = new nav();
	navigation.update();
	navigation.navController();

	formController();
	modal = new modalClass();
});

/*-------------------------------------------------------------
Delay
-------------------------------------------------------------*/

let delay = (n) => {
	n = n || 2000;
	return new Promise(done => {
		setTimeout(() => {
			done();
		}, n);
	});
}

/*-------------------------------------------------------------
Barba - Page Transition
-------------------------------------------------------------*/

barba.init({
	preventRunning: true,
	transitions: [
		{
			name: "default",
			sync: true,
			async beforeLeave(data) {
				pageTransition.play();
				await delay(2000);
			},
			enter() {
				navigation.update();
				navigation.closeMobileMenu();
			},
			after() {
				appearAnimations();
				navigation.navController();
				setTimeout(pictureMask.catchMasks(), 500);
				setTimeout(stickyTitle.catch(), 500);
				formController();
			},
		},
	],
	views: [
		{
			namespace: "home",
			afterEnter(data) {
				page = new mainpage();
			},
		},

		{
			namespace: "gallery",
			afterEnter(data) {
				page = new gallery();
			},
		},

		{
			namespace: "products",
			afterEnter(data) {
				page = new product();
			},
		},

		{
			namespace: "news",
			afterEnter(data) {
				page = new gallery();
			},
		},

		{
			namespace: "organization",
			afterEnter(data) {
				page = new organizationClass();
			},
		},
	],
});